import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import withAsyncImport from "../containers/hocs/other/WithAsyncImport";

/* Template */
const AppTemplate = withAsyncImport(
  () => import("./../containers/templates/AppTemplate/AppTemplate")
);

/* Util */
const NotFoundPage = withAsyncImport(
  () => import("./../pages/NotFound/NotFound")
);
const ErrorPage = withAsyncImport(
  () => import("./../pages/ErrorPage/ErrorPage")
);

/* Routes */
const RegisterCustomerPage = withAsyncImport(
  () => import("./../pages/RegisterCustomer/RegisterCustomer")
);
const CheckoutSetupSession = withAsyncImport(
  () => import("./../pages/CheckoutSetupSession/CheckoutSetupSession")
);
const SuccessCustomerRegistrationPage = withAsyncImport(
  () =>
    import("./../pages/CustomerSuccessRegistration/CustomerSuccessRegistration")
);
const TermsPage = withAsyncImport(() => import("./../pages/Terms/Terms"));
const PrivacyPage = withAsyncImport(() => import("./../pages/Privacy/Privacy"));

export interface ApplicationRoutesProps {}

interface ApplicationRoutesState {}

class ApplicationRoutes extends React.Component<
  ApplicationRoutesProps,
  ApplicationRoutesState
> {
  constructor(props: ApplicationRoutesProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<AppTemplate />}>
            <Route index element={<RegisterCustomerPage />} />
            <Route
              path="/customer-success"
              element={<SuccessCustomerRegistrationPage />}
            />
            <Route
              path="/checkout-setup/:session_id"
              element={<CheckoutSetupSession />}
            />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default ApplicationRoutes;
