import React from "react";
import ErrorBoundary from "./containers/helpers/ErrorBoundary";
import ApplicationRoutes from "./routes";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <ApplicationRoutes />
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
